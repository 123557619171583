<template>
  <div class="col-auto">
    <Button color="green_light" width="100" btnText="Login" icon="arrow" @buttonClicked="isLogin = true" >
      <IconLogIn color="white" />
    </Button>
    <transition name="fade">
      <LoginPopup v-if="isLogin" @close="isLogin = false" @loggedIn="isLogin = false"/>
    </transition>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconLogIn: defineAsyncComponent(() => import('@/components/icons/IconLogIn.vue')),
    LoginPopup: defineAsyncComponent(() => import('@/views/auth/LoginPopup.vue'))
  },
  name: 'Recurring Payment Login Footer',
  data () {
    return {
      isLogin: false
    }
  }
}

</script>
